@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	background-color: whitesmoke;
}

.my-masonry-grid {
	display: -webkit-box; /* Not needed if autoprefixing */
	display: -ms-flexbox; /* Not needed if autoprefixing */
	display: flex;
	margin-left: -30px; /* gutter size offset */
	width: auto;
}
.my-masonry-grid_column {
	padding-left: 30px; /* gutter size */
	background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
	/* change div to reference your elements you put in <Masonry> */
	margin-bottom: 30px;
}
